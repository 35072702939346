import { reactAppS3URLVersion1 } from '../checkers';
import { getTenantOrgId } from '../retainHistory';

export const s3Mapping = [
    { name: 'Lehigh', orgId: 1, buildFolderName: 'lehigh' },
    { name: 'CHOPS', orgId: 7, buildFolderName: 'chop' },
    { name: 'MMC', orgId: 8, buildFolderName: 'mainehealth' },
    { name: 'Ovrture', orgId: 12, buildFolderName: 'ovrture' },
    { name: 'Tulane', orgId: 16, buildFolderName: 'tulane' },
    { name: 'VCU', orgId: 17, buildFolderName: 'vcu' },
    { name: 'UCSF', orgId: 18, buildFolderName: 'ucsf' },
    { name: 'UVA', orgId: 19, buildFolderName: 'virginia' },
    { name: 'Susquehanna', orgId: 25, buildFolderName: 'susqu' },
    { name: 'BCHT', orgId: 26, buildFolderName: 'childrenshospital' },
    { name: 'TCU', orgId: 27, buildFolderName: 'tcu' },
    { name: 'NCSU', orgId: 28, buildFolderName: 'ncsu' },
    { name: 'Bucknell', orgId: 29, buildFolderName: 'bucknell' },
    { name: 'PEDDIE', orgId: 31, buildFolderName: 'peddie' },
    { name: 'IUHF', orgId: 33, buildFolderName: 'iuhealthfoundation' },
    { name: 'DICKINSON', orgId: 34, buildFolderName: 'dickinson' },
    { name: 'Muhlenberg', orgId: 35, buildFolderName: 'muhlenberg' },
    { name: 'CU_Denver', orgId: 36, buildFolderName: 'ucdenver' },
    { name: 'Intermountain', orgId: 37, buildFolderName: 'intermountain' },
    { name: 'MD+Anderson', orgId: 38, buildFolderName: 'mdanderson' },
    { name: 'BAYLOR', orgId: 39, buildFolderName: 'baylor' },
    { name: 'Stanford+University-New', orgId: 40, buildFolderName: 'stanford' },
    { name: 'UCDavis', orgId: 41, buildFolderName: 'ucdavis' },
    { name: 'Common+Spirit', orgId: 42, buildFolderName: 'commonspirit' },
    { name: 'UC+Riverside', orgId: 43, buildFolderName: 'ucriverside' },
    { name: 'Marts%26Lundy+', orgId: 44, buildFolderName: 'martsandlundy' },
    { name: 'DePaul+University', orgId: 45, buildFolderName: 'depaul' },
    { name: 'Harvard+Business+School', orgId: 46, buildFolderName: 'hbs' },
    { name: 'Barnard', orgId: 47, buildFolderName: 'barnard' },
    { name: 'Indiana+University', orgId: 48, buildFolderName: 'iuf' },
    { name: 'University+of+Delaware', orgId: 50, buildFolderName: 'udel' },
    { name: 'Auburn+University', orgId: 51, buildFolderName: 'auburn' },
    { name: 'Ovrture+University+POC', orgId: 52, buildFolderName: 'ou' },
    { name: 'Ovrture+Health+POC', orgId: 53, buildFolderName: 'oh' },
    { name: 'oglebay', orgId: 54, buildFolderName: 'oglebay' },
    { name: 'Amherst+college', orgId: 55, buildFolderName: 'amherst' },
    { name: 'MONTANA', orgId: 56, buildFolderName: 'montana' },
    { name: 'WVUF', orgId: 57, buildFolderName: 'wvuf' },
    { name: 'ST.THOMAS-NEW', orgId: 58, buildFolderName: 'stthomas' },
    { name: 'SJU', orgId: 59, buildFolderName: 'sju' },
    { name: 'CalTech', orgId: 60, buildFolderName: 'caltech' },
    { name: 'Alzheimer', orgId: 61, buildFolderName: 'alz' },
    { name: 'EARTHJUSTICE', orgId: 62, buildFolderName: 'earthjustice' },
    { name: 'CNH', orgId: 63, buildFolderName: 'childrensnational' },
    { name: 'Boston+College', orgId: 64, buildFolderName: 'bc' },
    { name: 'NIU', orgId: 65, buildFolderName: 'niu' },
    { name: 'USF', orgId: 66, buildFolderName: 'usf' },
    { name: 'UNIVERSITY+OF+BRITISH+COLUMBIA', orgId: 67, buildFolderName: 'ubc' },
    { name: 'United+State+Navel+Academy', orgId: 68, buildFolderName: 'usna' },
    { name: 'Mass+general+hospital', orgId: 69, buildFolderName: 'mgh' },
    { name: 'MPI', orgId: 70, buildFolderName: 'midpac' },
    { name: 'Org_71', orgId: 71, buildFolderName: 'tch' },
    { name: 'Rice+University', orgId: 72, buildFolderName: 'rice' },
    { name: 'Ball+State+University', orgId: 73, buildFolderName: 'ballstate' },
    { name: 'Boston+University', orgId: 74, buildFolderName: 'boston' },
    { name: 'University+of+Memphis', orgId: 75, buildFolderName: 'umem' },
    { name: 'The_Branson_School', orgId: 76, buildFolderName: 'branson' },
    { name: 'Campbell+Hall', orgId: 77, buildFolderName: 'campbell' },
    { name: 'Ovrture+University+POC', orgId: 78, buildFolderName: 'oup' },
    { name: 'Ovrture+Health+POC', orgId: 79, buildFolderName: 'ohp' },
    { name: 'Cincinnati+Children+Hospital', orgId: 80, buildFolderName: 'cch' },
    { name: 'National+Geographic', orgId: 81, buildFolderName: 'natgeo' },
    { name: 'Colonial+Williamsburg', orgId: 82, buildFolderName: 'cwf' },
    { name: 'Lawrenceville+School', orgId: 83, buildFolderName: 'lrs' },
    { name: 'OSU', orgId: 84, buildFolderName: 'osu' },
    { name: 'NYU', orgId: 85, buildFolderName: 'nyu' },
    { name: 'UVALAW', orgId: 86, buildFolderName: 'uval' },
    { name: 'University+of+Miami', orgId: 87, buildFolderName: 'uofmiami' },
    { name: 'SBU', orgId: 88, buildFolderName: 'sbu' },
    { name: 'The+Movement+for+Black+Lives', orgId: 89, buildFolderName: 'm4bl' },
    { name: 'ChildrensMercy', orgId: 90, buildFolderName: 'cm' },
    { name: 'Seattle+Childrens', orgId: 91, buildFolderName: 'sch' },
    { name: 'Avera+Foundation', orgId: 92, buildFolderName: 'af' },
    { name: 'WU', orgId: 93, buildFolderName: 'wu' },
    { name: 'Rams_Club', orgId: 94, buildFolderName: 'rmc' },
    { name: 'Org_96', orgId: 96, buildFolderName: 'siena' },
    { name: 'Org_95', orgId: 95, buildFolderName: 'sbp' },
    { name: 'Org_98', orgId: 98, buildFolderName: 'slu' },
    { name: 'Org_99', orgId: 99, buildFolderName: 'childrenshealth' },
    { name: 'Org_97', orgId: 97, buildFolderName: 'bt1d' },
];

export const fetchS3Url = (orgId) => {
   
    let obj = s3Mapping.find((f) => f.orgId === orgId);
    if (orgId && obj?.name) {
        return reactAppS3URLVersion1.includes(Number(orgId))
            ? `${process?.env?.REACT_APP_S3_URL_VERSION1}/Org_${orgId}`
            : `${process?.env?.REACT_APP_S3_URL}/${obj?.name}/assets`;
    } else {
         const orgId_ = getTenantOrgId();
        return orgId_ && reactAppS3URLVersion1.includes(Number(orgId_))
            ? process?.env?.REACT_APP_S3_URL_VERSION1
            : process?.env?.REACT_APP_S3_URL;
    }
};

export const fetchS3TenantName = (orgId) => {
    let obj = s3Mapping.find((f) => f.orgId === orgId);
    return obj?.name;
};
export const fetchBuildFolderTenantName = (orgId) => {
    let obj = s3Mapping.find((f) => f.orgId === orgId);
    return obj?.buildFolderName;
}

export const fetchS3LoginLogoUrl = () => {
    const orgId = getTenantOrgId();
    return orgId && reactAppS3URLVersion1.includes(Number(orgId))
        ? `${process?.env?.REACT_APP_S3_URL_VERSION1}/client-logos/logo_`
        : `${process?.env?.REACT_APP_S3_URL}/ui/images/client-logos/logo_`;
};
