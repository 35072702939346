// @flow
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import TreeView from '../../../components/treeview';
import {
    assetFolderViewReset,
    assetFolderViewInitialListChange,
    assetBulkUploadResource,
    assetBulkUploadReset,
    assePreview,
    assetReset,
    assetUploadImpact,
    impactListByIdYear,
    impactUpdate,
    assetUploadImageFund,
    assePreviewDoc,
} from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTreeViewContext } from '../../../contexts/TreeViewContext';
import { modalActions, treeViewActions } from '../../../reducers/actions';
import { treeData } from '../../../constants/treeData';
import { useModalContext } from '../../../contexts/ModalContext';
import FileUploader from '../../../components/FileUploader';
import Timeline from './Timeline';
import TimelineItem from './TimelineItem';
import { getFileExtension, containsOnlyNumbers, toDataURL } from '../../../utility';
import checkIcon from '../../../assets/images/icons/download-file-icon.svg';
import { CustomLoader } from '../../customLoader';
import { setEditorChanges } from '../storage';
import { RedirectTo } from '../../../routes/role/path';
import { useNotification } from '@ovrture/react-redux/dist/helpers/toasterNotification/NotificationContext';
import { initialSlideCenter } from '../slick/SlickSlider';
import { tinymceInlineInit } from '../tinymce/TinyMceInlineTextEditor';
import { getTenantOrgId, spacePreventDefault } from '../../retainHistory';
import { checkOrgForImpactImageForCropper } from '../../checkers';
import { treeLimit } from '../../genericFunction';
import { deselectNode, getAllPathIds } from '../../../components/treeview/react-simple-jstree';
import {
    ASSET_TYPE_VIDEO,
    isNotVimeo,
    OLD_ASSET_TYPE_VIDEO,
    WistiaEditorIFrame,
} from '../../wistia/WistiaEditorIFrame';

const ReplaceAssetEditorModal = (props) => {
    const { modalDispatch } = useModalContext();
    const { treeState, treeDispatch } = useTreeViewContext();
    const [addfolderDisabled, setAddFolderDisabled] = useState(true);
    const [uploadDisabled, setuploadDisabled] = useState(true);
    const [treeListData, setTreeListData] = useState(null);
    const [data, setData] = useState([]);
    const [toNode, setToNode] = useState(null);
    const dispatch = useDispatch();
    const [files, setFiles] = useState();
    const [image, setImage] = useState(null);
    const [loader, setLoader] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [keydown, setKeydown] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);
    const [index, setIndex] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false);
    const [contentData, setContentData] = useState();
    const [fileName, setFileName] = useState('');
    const errorMessage = 'Asset upload failed to file size limitation.';
    const { Toast } = useNotification();
    const url = new URL(window.location.href);
    const [dataIndex, setdataIndex] = useState(null);
    const [att, setatt] = useState(null);
    const [sectionId, setsectionId] = useState(null);
    const [isImpactPanel, setisImpactPanel] = useState(null);
    const [fundImpactTblRecipientImpactId, setfundImpactTblRecipientImpactId] = useState(null);
    const [isDescriptionPanel, setisDescriptionPanel] = useState(null);
    let pathnames = ['site-edit', 'report-edit'];
    const {
        auth,
        impactListByYear,
        initialList,
        uploadResource,
        socketSuccess,
        assetPreviewImage,
        progressBar,
        preview,
        propsData,
    } = useSelector((state) => ({
        propsData: state?.brand?.tempData,
        impactListByYear: state?.impact?.listByIdYear,
        initialList: state?.assetFolderView?.initialListChange,
        uploadResource: state?.assetBulkUpload?.uploadResource,
        socketSuccess: state?.socket?.success,
        assetPreviewImage: state?.asset?.preview,
        progressBar: state?.socket?.loadingList,
        preview: state?.asset?.previewDoc,
        auth: state?.auth?.me,
    }));
    useEffect(() => {
        if (propsData?.flag) {
            for (let index = 0; index < data.length; index++) {
                refreshTreeData(data[index]);
            }
            setData([...data]);
            deselectNode();
            setAddFolderDisabled(true);
            setuploadDisabled(true);
        }
    }, [propsData?.flag]);

    const refreshTreeData = (el) => {
        let propsDataFlag = propsData?.flag;
        let disabled = false;
        if (
            propsDataFlag === 2 &&
            (el.icon == 'glyphicon glyphicon-doc-file' ||
                el.icon == 'glyphicon glyphicon-video' ||
                el.icon == 'glyphicon glyphicon-pdf-file')
        ) {
            // image only
            disabled = true;
        }
        if (propsDataFlag === 3 && (el.icon == 'glyphicon glyphicon-image' || el.icon == 'glyphicon glyphicon-video')) {
            // doc only
            disabled = true;
        }
        if (
            propsDataFlag === 4 &&
            (el.icon == 'glyphicon glyphicon-doc-file' || el.icon == 'glyphicon glyphicon-pdf-file')
        ) {
            // doc not only
            disabled = true;
        }

        if (el?.children?.length > 0) {
            el?.children?.forEach((el) => {
                refreshTreeData(el);
            });
        } else {
            el.state = {
                disabled,
            };
        }
    };

    useEffect(() => {
        setInitData();
        window.addEventListener('keydown', (e) => {
            if (
                e.target.closest('.visually-visible-modal.modal.show') &&
                e.target.closest('.visually-visible-modal.modal.show').querySelector('h4').innerText !==
                    'Broadcast Alert' &&
                (window?.location?.pathname === RedirectTo('/content-panel-edit') ||
                    window?.location?.pathname?.includes(RedirectTo('/content-broadcast-create')) ||
                    window?.location?.pathname?.includes(RedirectTo('/site-edit')) ||
                    window?.location?.pathname?.includes(RedirectTo('/report-edit'))) &&
                !e.target.closest('.visually-visible-modal.modal.show #scriptCode') &&
                !e.target.closest('.visually-visible-modal.modal.show #embedUrl') &&
                !e.target.closest('.visually-visible-modal.modal.show .printBridgeHeading')
            ) {
                if ((!keydown && e.key == ' ') || e.code == 'Space' || e.keyCode == 32) {
                    setKeydown(true);
                    spacePreventDefault(e);
                }
                window.addEventListener('keyup', function () {
                    setKeydown(false);
                    e.preventDefault();
                });
            }
        });
        if (propsData?.contentData) {
            setData(propsData?.contentData);
            let activeJstreePath = JSON.parse(localStorage.getItem('activeJstreePath'));
            if (activeJstreePath?.length > 0) {
                setTimeout(() => {
                    const ele = document.getElementById(activeJstreePath[0]);
                    if (ele) {
                        ele.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
                    }
                }, 100);
                let id = activeJstreePath[activeJstreePath?.length - 1];
                setTimeout(() => {
                    getInitialFolderList(id);
                }, 1000);
                setTimeout(() => {
                    let element = document?.getElementById(id)?.querySelector('.jstree-anchor');
                    if (element) element.click();
                }, 2500);
            }
        } else {
            setTimeout(() => {
                getInitialFolderList();
            }, 1000);
        }
        return () => {
            setIsSubmited(false);
            setLoader(false);
            setIsSubmit(false);
        };
    }, []);

    const setInitData = () => {
        const ele = document.getElementById('rootComponent');
        const dataIndex = ele.getAttribute('data-index-root');
        const att = ele?.getAttribute('slidesecclass-id');
        const sectionId = ele.getAttribute('section-id');
        const fund_impact = ele.getAttribute('fund_impact');
        const fund_description = ele.getAttribute('fund_description');
        const fund_impact_tbl_recipient_impact_id = ele.getAttribute('fund_impact_tbl_recipient_impact_id');
        setdataIndex(dataIndex);
        setatt(att);
        setsectionId(sectionId.replace('outer', ''));
        setisImpactPanel(fund_impact);
        setisDescriptionPanel(fund_description);
        setfundImpactTblRecipientImpactId(fund_impact_tbl_recipient_impact_id);
    };
    useEffect(() => {
        if (
            keydown &&
            document?.querySelector('.modal.visually-visible-modal.show .modal-title')?.innerText === 'Replace Asset'
        ) {
            if (
                window?.location?.pathname === RedirectTo('/content-panel-edit') ||
                window?.location?.pathname?.includes(RedirectTo('/content-broadcast-create')) ||
                window?.location?.pathname?.includes(RedirectTo('/site-edit')) ||
                window?.location?.pathname?.includes(RedirectTo('/report-edit'))
            ) {
                if (
                    contentData?.icon === 'glyphicon glyphicon-image' ||
                    contentData?.icon === 'glyphicon glyphicon-pdf-file' ||
                    contentData?.icon === 'glyphicon glyphicon-video'
                ) {
                    let isModalOpen = document?.querySelector(
                        '.modal.visually-visible-modal.show .asset-preview .modal-title'
                    )?.innerText;
                    if (!isModalOpen?.includes('Asset Preview') && !openModal) {
                        setOpenModal(true);
                        return false;
                    } else if (openModal) {
                        let btn = document?.querySelector('.modal.visually-visible-modal.show .asset-preview button');
                        if (btn?.innerText === 'Back') btn.click();
                        setOpenModal(false);
                        return false;
                    }
                } else if (contentData?.icon === 'glyphicon glyphicon-pdf-file') {
                    // dispatch(assePreviewDoc({ assetFolderViewId: contentData?.id }));
                    return false;
                } else {
                    return false;
                }
            }
        }
    }, [keydown]);

    useEffect(() => {
        if (openModal) {
            modalDispatch({
                type: modalActions.OPEN_MODAL,
                payload: {
                    modalAction: () => {
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    },
                    data: {
                        data: contentData,
                        contentData: data,
                        activeJstreePath: JSON.parse(localStorage.getItem('activeJstreePath')),
                        assetPreviewProps: props?.data,
                        setOpenModal: setOpenModal,
                    },
                    modalVariant: 'primary',
                    modalDataKey: ['common', 'assetPanelPreview'],
                },
            });
        }
    }, [openModal]);

    const getInitialFolderList = (parentId = '#') => {
        let ele = document.getElementById(parentId);
        if (document?.querySelector('.visually-visible-modal.modal.show')) {
            ele = document?.querySelector(`.visually-visible-modal.modal.show #${CSS.escape(parentId)}`);
        }
        if (ele) ele.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
        let processId = '';
        if (window.location.pathname === RedirectTo('/content-panel-edit')) {
            processId = 0;
        } else if (
            auth?.role?.name === 'REPORT ADMIN' ||
            auth?.role?.name === 'CONTENT ADMIN' ||
            auth?.role?.name === 'BRAND ADMIN'
        ) {
            processId = 2;
        }
        dispatch(assetFolderViewInitialListChange({ processId, parentId }));
    };

    useEffect(() => {
        if (treeState?.treeData) {
            treeDispatch({
                type: treeViewActions.GET_TREE_DATA,
                payload: {
                    treeData: treeData,
                },
            });
        }
        if (treeState?.treeData) {
            setTreeListData(treeState);
        }
    }, [treeState?.treeData]);

    useEffect(() => {
        if (initialList?.data) {
            async function createInitialData() {
                //await manageTreeData(data);
                await manageTreeData(initialList?.data, setData, data, 'ReplaceAssetJstreeRendaring');
            }
            if (data.length > 0) {
                createInitialData(initialList?.data);
            } else {
                initialList?.data?.forEach((el) => {
                    if (el?.text === 'RECYCLE BIN') return;
                    data.push({
                        id: el.id,
                        parent_id: el.id,
                        text: el?.name || el?.text,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                        userId: el.userId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                    });
                });
                setData([...data]);
            }
            dispatch(assetFolderViewReset());
        }
    }, [initialList]);

    const onCroppedImage = (img) => {
        setDynamicAsset(img, 'image');
        setImage(img);
    };

    const handleAssetUploadImpact = (img, type, originalImg) => {
        let primaryId = fundImpactTblRecipientImpactId;
        let formData;
        if (type === 'image') {
            formData = {
                assetAltText: img?.altText,
                originalAssetURL: originalImg,
                colImpactAssetUrl: img?.base64Image,
                fileName: img?.filename,
            };
            if (img?.imgForImpactAPI?.base64Image) {
                delete formData.colImpactAssetUrl;
                formData.fileName = img?.imgForImpactAPI?.filename;
                formData.requestFileBytes = img?.imgForImpactAPI?.base64Image?.split(',')[1].trim();
            }
        } else if (type === 'gif') {
            formData = {
                assetAltText: img?.altText,
                originalAssetURL: originalImg,
                requestFileBytes: '',
                assetURL: img?.base64Image,
                fileName: img?.filename,
            };
        } else {
            formData = {
                assetAltText: img?.altText,
                originalAssetURL: originalImg,
                requestFileBytes: '',
                assetURL: img,
                fileName: fileName,
            };
        }

        if (containsOnlyNumbers(primaryId)) {
            formData.primaryId = primaryId;
            dispatch(assetUploadImpact(formData));
        } else {
            if (impactListByYear?.code == 200) {
                let payload = {
                    pkFundImpactId: impactListByYear?.data?.pkFundImpactId,
                    fkFundId: impactListByYear?.data?.fkFundId,
                    fkImpactTypeId: impactListByYear?.data?.tblImpactType?.pkImpactTypeId,
                    colImpactReportYear: url?.searchParams?.get('reportYear'),
                };
                if (type === 'gif') {
                    payload.colImpactAssetFileName = img?.filename;
                    payload.colImpactAssetUrl = img?.base64Image;
                } else if (img?.filename && img?.base64Image) {
                    payload.colImpactAssetFileName = img?.filename;
                    let imgLen = img?.base64Image?.split(',');
                    payload.colImpactAssetAltText = img?.altText;
                    payload.colImpactOriginalAssetUrl = originalImg;
                    if (imgLen?.length === 1) {
                        payload.colImpactAssetUrl = img?.base64Image;
                    } else {
                        payload.fundImpactAssetFileBytes = img?.base64Image?.split(',')[1].trim();
                    }
                } else {
                    payload.colImpactAssetFileName = contentData?.text;
                    payload.colImpactAssetUrl = img;
                    payload.colImpactAssetAltText = img?.altText;
                    payload.colImpactOriginalAssetUrl = originalImg;
                }
                dispatch(impactUpdate(payload));
            }
        }
    };

    useEffect(() => {
        const slideId = document?.querySelector('#rootComponent')?.getAttribute('slidesecclass-id');
        const year = url?.searchParams?.get('reportYear');
        if (slideId) {
            const impactId = document
                ?.querySelector(`#${slideId} .item.active [data-impactid]`)
                ?.getAttribute('data-impactid');
            if (containsOnlyNumbers(impactId)) {
                dispatch(
                    impactListByIdYear({ pkFundImpactId: impactId, reportYear: url?.searchParams?.get('reportYear') })
                );
            }
        }
    }, []);

    let blankImgArray = ['blank-image.png', 'image-right.png'];

    useEffect(() => {
        if (assetPreviewImage?.data) {
            setInitData();
            // console.log(assetPreviewImage?.data);
            if (
                propsData?.page !== 'asset-replacer' &&
                assetPreviewImage?.data?.toLocaleLowerCase()?.includes('.pdf') &&
                contentData?.icon === 'glyphicon glyphicon-pdf-file'
            ) {
                window.open(assetPreviewImage?.data, '_blank');
            } else {
                const ele = document.getElementById('rootComponent');
                const slides = ele.getAttribute('slidesecclass-id');
                const sectionId = ele.getAttribute('section-id');
                const dataIndex = ele.getAttribute('data-index-root');
                let oldElement = null;
                if (dataIndex) {
                    oldElement = slides
                        ? document.querySelectorAll(
                              `#${slides} .item.active [data-index="${dataIndex}"] [sectionid=${sectionId}]`
                          )
                        : document.querySelectorAll(
                              `.item.active [data-index="${dataIndex}"] [sectionid=${sectionId}]`
                          );
                } else {
                    oldElement = slides
                        ? document.querySelectorAll(`#${slides} .item.active  [sectionid=${sectionId}]`)
                        : document.querySelectorAll(`.item.active  [sectionid=${sectionId}]`);
                }
                if (oldElement?.length === 0) {
                    oldElement = document.querySelectorAll(`[sectionid=${sectionId}]`);
                }
                if (window.location.pathname === RedirectTo('/content-broadcast-create')) {
                    oldElement = document.querySelectorAll(`.broadCastTemplpatesWrapper [sectionid=${sectionId}]`);
                }
                let computedStyle = oldElement && oldElement.length > 0 ? window.getComputedStyle(oldElement[0]) : null;
                let extraHight = computedStyle
                    ? Number(computedStyle.marginTop.replace('px', '')) +
                      Number(computedStyle.marginBottom.replace('px', ''))
                    : 0;
                let extraWidth = computedStyle
                    ? Number(computedStyle.marginLeft.replace('px', '')) +
                      Number(computedStyle.marginRight.replace('px', ''))
                    : 0;
                extraHight =
                    checkOrgForImpactImageForCropper?.includes(Number(getTenantOrgId())) &&
                    oldElement[0]?.classList?.contains('assetReplacerClassForImpact')
                        ? 0
                        : extraHight;
                extraWidth =
                    checkOrgForImpactImageForCropper?.includes(Number(getTenantOrgId())) &&
                    oldElement[0]?.classList?.contains('assetReplacerClassForImpact')
                        ? 0
                        : extraWidth;
                const oE = document.getElementsByClassName(sectionId);
                let divWidth = oldElement[0]?.clientWidth + extraWidth;
                let divHeight = oldElement[0]?.clientHeight + extraHight;
                const tempRatio = divHeight / divWidth;
                if (window.innerWidth > 1200) {
                    if (700 < divWidth) {
                        divWidth = 700;
                        divHeight = 700 * tempRatio;
                    }
                } else if (1201 > window.innerWidth) {
                    if (550 < divWidth) {
                        divWidth = 550;
                        divHeight = 550 * tempRatio;
                    }
                }
                if (isSubmited) {
                    setIsSubmited(false);
                    const fileData = getFileExtension({ text: assetPreviewImage?.data });
                    if (
                        fileData.toLowerCase() === 'jpg' ||
                        fileData.toLowerCase() === 'jpeg' ||
                        fileData.toLowerCase() === 'png' ||
                        fileData.toLowerCase() === 'svg' ||
                        fileData.toLowerCase() === 'gif'
                    ) {
                        if (fileData.toLowerCase() === 'gif') {
                            setDynamicAsset({ base64Image: assetPreviewImage?.data, filename: fileName }, 'gif');
                        } else {
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: (e) => {
                                        setTimeout(() => {
                                            modalDispatch({
                                                type: modalActions.CLOSE_MODAL,
                                                payload: {
                                                    noActive: true,
                                                },
                                            });
                                        }, 10);
                                    },
                                    modalVariant: 'primary',
                                    modalDataKey: ['systemAdmin', 'cropImage'],
                                    data: {
                                        image: assetPreviewImage?.data,
                                        altTextVal: blankImgArray.some((url) =>
                                            oldElement[0]?.getAttribute('src')?.includes(url)
                                        )
                                            ? ''
                                            : oldElement[0]?.getAttribute('alt') || '',
                                        setCropData: onCroppedImage,
                                        filename: toNode?.original?.text,
                                        name: 'assetReplacer',
                                        minCropBoxWidth: divWidth,
                                        minCropBoxHeight: divHeight,
                                        editorReplaceCropImageApiNeeded: true,
                                    },
                                },
                            });
                        }
                    } else if (
                        assetPreviewImage?.data.includes(ASSET_TYPE_VIDEO) ||
                        assetPreviewImage?.data.includes(OLD_ASSET_TYPE_VIDEO)
                    ) {
                        setDynamicAsset(assetPreviewImage?.data, 'video');
                    } else {
                        setDynamicAsset(assetPreviewImage?.data, 'file');
                    }
                }
                dispatch(assetReset());
            }
        }
    }, [assetPreviewImage]);

    //let countTotalJsTreeChildren = 0;
    const manageTreeData = (initialListData, setData, data, AssetsJstreeRendaring) => {
        if (initialListData?.length > 0) {
            const propsDataFlag = propsData?.flag || 0;
            let elemnt_ = document.getElementById(initialListData[0]?.parentId);
            let elemntArray_ = elemnt_.parentNode.childNodes;
            var index_ = Array.from(elemntArray_).indexOf(elemnt_);
            const clonedArray_ = JSON.parse(localStorage.getItem(AssetsJstreeRendaring)) || [];
            if (!clonedArray_?.find((f) => f.id === elemnt_.id)) {
                clonedArray_.push({ index: index_, id: elemnt_.id });
            }
            localStorage.setItem(AssetsJstreeRendaring, JSON.stringify(clonedArray_));
            let pathArray = getAllPathIds(initialListData[0]?.parentId);
            let obj = null;
            for (let index = 0; index < pathArray?.length; index++) {
                const clonedArray = JSON.parse(localStorage.getItem(AssetsJstreeRendaring));
                let new_ = clonedArray?.find((f) => f.id === pathArray[index]);
                if (index === 0) {
                    obj = data[new_.index];
                } else {
                    obj = obj.children[new_.index];
                }
                if (index === pathArray?.length - 1) {
                    initialListData?.forEach((el) => {
                        if (!obj?.children?.find((f) => f?.id === el?.id)) {
                            //d.child = false;
                            let disabled = false;
                            if (
                                propsDataFlag === 2 &&
                                (el.icon == 'glyphicon glyphicon-doc-file' ||
                                    el.icon == 'glyphicon glyphicon-video' ||
                                    el.icon == 'glyphicon glyphicon-pdf-file')
                            ) {
                                // image only
                                disabled = true;
                            }
                            if (
                                propsDataFlag === 3 &&
                                (el.icon == 'glyphicon glyphicon-image' || el.icon == 'glyphicon glyphicon-video')
                            ) {
                                // doc only
                                disabled = true;
                            }
                            if (
                                propsDataFlag === 4 &&
                                (el.icon == 'glyphicon glyphicon-doc-file' || el.icon == 'glyphicon glyphicon-pdf-file')
                            ) {
                                // doc not only
                                disabled = true;
                            }
                            obj.children.push({
                                id: el.id,
                                parent_id: el.parentId,
                                text: el?.text || el?.name,
                                icon: el.icon,
                                children: [],
                                child: el.child,
                                adminId: el.adminId,
                                assetId: el.assetId,
                                brandId: el.brandId,
                                fkRoleId: el.fkRoleId,
                                fkOrgId: el.fkOrgId,
                                fkPaletteId: el.fkPaletteId,
                                userId: el.userId,
                                assetRootNodeId: el.assetRootNodeId,
                                permissionbyId: el.permissionbyId,
                                canBeDeleted: el.canBeDeleted,
                                state: {
                                    disabled,
                                },
                            });
                        }
                    });
                }
            }
            setData([...data]);
        }
    };

    function handleGetList(e, list) {
        // setAddFolderDisabled(true);
        setFileName(list?.node?.original.text);
        setToNode(list?.node);
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(list?.node?.original?.id);
        }
        setContentData({ ...list?.node?.original, parentRootId: list?.node?.parent });
        if (list?.node?.original?.id) {
            const iconName = list?.node?.original?.icon?.trim() || '';
            if (
                (list?.node.parent === '#' && list?.node?.original.text === 'SYSTEM CLOUD') ||
                (iconName == 'mdi mdi-cloud' && list?.node?.original.text == 'BRAND CLOUD') ||
                (iconName == 'mdi mdi-cloud' && list?.node?.original.text == 'USER CLOUD') ||
                (iconName === 'mdi mdi-folder-lock' && list?.node?.original.text == 'COVER IMAGES')
            ) {
                setAddFolderDisabled(true);
                setuploadDisabled(true);
            } else if (
                auth?.role?.name === 'BRAND ADMIN' &&
                list?.node?.parents?.includes('1') &&
                (list?.node.children.length ||
                    list?.node.icon.trim() === 'mdi mdi-folder' ||
                    list?.node.icon.trim() === 'mdi mdi-folder-lock' ||
                    list?.node.icon.trim() === 'fa-fa-folder-brand-closed' ||
                    list?.node.icon.trim() === 'mdi mdi-folder-account')
            ) {
                setuploadDisabled(true);
                setAddFolderDisabled(true);
                if (
                    list?.node.icon === 'glyphicon glyphicon-video' ||
                    list?.node.icon === 'glyphicon glyphicon-image'
                ) {
                    const icon =
                        list?.node.icon.trim() === 'glyphicon glyphicon-image' ||
                        list?.node.icon.trim() === 'glyphicon glyphicon-video';
                    const status = propsData?.isImageDisable === false && icon ? false : true;
                    setAddFolderDisabled(status);
                    setuploadDisabled(true);
                } else if (
                    list?.node.icon === 'glyphicon glyphicon-doc-file' ||
                    list?.node.icon === 'glyphicon glyphicon-pdf-file'
                ) {
                    setAddFolderDisabled(false);
                    setuploadDisabled(true);
                    if (
                        propsData?.element?.classList.contains('assetReplacerClassForImpact') ||
                        propsData?.element?.querySelector('.assetReplacerClassForImpact') ||
                        propsData?.element?.classList.contains('assetReplacerClassForFundDescription') ||
                        propsData?.element?.querySelector('.assetReplacerClassForFundDescription')
                    ) {
                        setAddFolderDisabled(true);
                    }
                }
            } else if (
                list?.node.children.length ||
                list?.node.icon.trim() === 'mdi mdi-folder' ||
                list?.node.icon.trim() === 'mdi mdi-folder-lock' ||
                list?.node.icon.trim() === 'fa-fa-folder-brand-closed' ||
                list?.node.icon.trim() === 'mdi mdi-folder-account'
            ) {
                setAddFolderDisabled(true);
                setuploadDisabled(false);
            } else if (
                list?.node.icon === 'glyphicon glyphicon-video' ||
                list?.node.icon === 'glyphicon glyphicon-image'
            ) {
                const icon =
                    list?.node.icon.trim() === 'glyphicon glyphicon-image' ||
                    list?.node.icon.trim() === 'glyphicon glyphicon-video';
                const status = propsData?.isImageDisable === false && icon ? false : true;
                setAddFolderDisabled(status);
                setuploadDisabled(true);
            } else if (
                list?.node.icon === 'glyphicon glyphicon-doc-file' ||
                list?.node.icon === 'glyphicon glyphicon-pdf-file'
            ) {
                setAddFolderDisabled(false);
                setuploadDisabled(true);
                if (
                    propsData?.element?.classList.contains('assetReplacerClassForImpact') ||
                    propsData?.element?.querySelector('.assetReplacerClassForImpact') ||
                    propsData?.element?.classList.contains('assetReplacerClassForFundDescription') ||
                    propsData?.element?.querySelector('.assetReplacerClassForFundDescription')
                ) {
                    setAddFolderDisabled(true);
                }
            }
        }
    }

    useEffect(() => {
        if (uploadResource && socketSuccess) {
            setLoader(false);
            setTimeout(() => {
                getInitialFolderList(toNode?.id);
                files[index].uploaded = true;
                setFiles(files);
                dispatch(assetBulkUploadReset());
                fileUploaded(index);
            }, 1000);
        }
    }, [uploadResource, socketSuccess]);

    const dummyUpload = (index) => {
        files[index].progressBar = { error: errorMessage };
        files[index].uploaded = true;
        setFiles(files);
        setTimeout(() => {
            fileUploaded(index);
        }, 2000);
    };

    const fileUploaded = (index) => {
        let newIndex = index + 1;
        if (newIndex <= files.length - 1) {
            setIndex(newIndex);
            onUploadFile(newIndex);
        } else {
            setTimeout(() => {
                setIndex(0);
                localStorage.removeItem('skipBulkOperation');
                setFiles(null);
                setIsSubmit(false);
            }, 1500);
        }
    };

    useEffect(() => {
        if (progressBar?.length > 0 && files) {
            if (files[index]) files[index].progressBar = progressBar;
            setFiles(files);
        }
    }, [progressBar]);

    const onUploadFile = (index = 0) => {
        setIsSubmit(true);
        localStorage.setItem('skipBulkOperation', true);
        if (files[index].typeFile === 'image' && files[index].fileSizeMore32MB) {
            dummyUpload(index);
        } else if (files[index].typeFile === 'video' && files[index].fileSizeMore1GB) {
            dummyUpload(index);
        } else {
            dispatch(assetBulkUploadResource({ parentId: parseInt(toNode?.id) }, { multipartFiles: [files[index]] }));
        }
    };

    const onSubmit = () => {
        // setLoader(true);
        setIsSubmited(true);
        dispatch(assePreview({ assetFolderViewId: toNode?.original?.id }));
    };

    const UploadFundImageVideo = (img, asset, originalImg) => {
        try {
            let sectionId = document.querySelector('.rootComponent');
            if (sectionId) {
                let slidesecclassId = sectionId?.getAttribute('slidesecclass-id');
                let ele = document.querySelector(`#${slidesecclassId} #FUND_DESCRIPTION `);
                if (ele) {
                    let activeCarousel = ele.querySelector('.fund-slides.carousel-item.active');
                    if (activeCarousel) {
                        let fundId = activeCarousel
                            .querySelector('.fundDescriptionElement')
                            ?.getAttribute('data-fundid');

                        let payload = null;
                        if (asset === 'image') {
                            let imgLen = img?.base64Image?.split(',');
                            if (imgLen?.length === 1) {
                                imgLen = img?.base64Image;
                                payload = {
                                    primaryId: fundId,
                                    requestFileBytes: '',
                                    assetURL: imgLen,
                                    fileName: img?.filename,
                                    originalAssetURL: originalImg,
                                };
                            } else {
                                imgLen = img?.base64Image?.split(',')[1].trim();
                                payload = {
                                    primaryId: fundId,
                                    requestFileBytes: imgLen,
                                    assetURL: '',
                                    fileName: img?.filename,
                                    originalAssetURL: originalImg,
                                };
                            }
                        } else if (asset === 'gif') {
                            payload = {
                                primaryId: fundId,
                                requestFileBytes: '',
                                assetURL: img?.base64Image,
                                fileName: img?.filename,
                                originalAssetURL: originalImg,
                            };
                        } else if (asset === 'video') {
                            payload = {
                                primaryId: fundId,
                                requestFileBytes: '',
                                assetURL: img,
                                fileName: fileName,
                                originalAssetURL: originalImg,
                            };
                        }

                        if (fundId && payload) dispatch(assetUploadImageFund(payload));
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const setDynamicAsset = (img, asset = 'image') => {
        try {
            const ele = document.getElementById('rootComponent');
            const dataIndex = ele.getAttribute('data-index-root');
            const att = ele?.getAttribute('slidesecclass-id');
            const sectionId = ele.getAttribute('section-id');
            const isImpactPanel = ele.getAttribute('fund_impact');
            const isDescriptionPanel = ele.getAttribute('fund_description');
            const fundImpactTblRecipientImpactId = ele.getAttribute('fund_impact_tbl_recipient_impact_id');
            window?.documentAssetReplaceDocumentIconPathFromReact &&
                window.documentAssetReplaceDocumentIconPathFromReact();
            if (isDescriptionPanel === 'true') {
                UploadFundImageVideo && UploadFundImageVideo(img, asset, assetPreviewImage?.data);
            }
            if (isImpactPanel === 'true') {
                handleAssetUploadImpact && handleAssetUploadImpact(img, asset, assetPreviewImage?.data);
            }
            let sectionNum = null;
            let idx = 0;
            var clss;
            let oldElement = null;
            if (sectionNum >= 0) {
                // Panels replcement
                if (window.location.pathname === RedirectTo('/content-panel-edit')) {
                    oldElement = document.querySelectorAll(`.item.active [sectionid=${sectionId}]`);
                    if (oldElement?.length > 1) {
                        oldElement = document.querySelectorAll(
                            `.item.active [data-index='${dataIndex}'] [sectionid=${sectionId}]`
                        );
                    }
                    if (oldElement?.length > 0) {
                        clss = oldElement[0].getAttribute('class');
                    }
                } else if (window.location.pathname === RedirectTo('/content-broadcast-create')) {
                    // broadcast alert replacement
                    oldElement = document.querySelectorAll(`[sectionid=${sectionId}]`);
                } else if (fundImpactTblRecipientImpactId) {
                    let element = document?.querySelector(`[impact-id="${fundImpactTblRecipientImpactId}"]`);
                    let element1 = document?.querySelector(`[data-impactid="${fundImpactTblRecipientImpactId}"]`);
                    if (element?.getAttribute('impact-id').toString().trim() === fundImpactTblRecipientImpactId) {
                        oldElement =
                            element?.closest('.img-wrapper')?.querySelectorAll('[sectionid]') ||
                            element?.closest('.recipient')?.querySelectorAll('[sectionid]');
                        clss = oldElement[0]?.getAttribute('class');
                    } else if (
                        element1?.getAttribute('data-impactid').toString().trim() === fundImpactTblRecipientImpactId
                    ) {
                        oldElement =
                            element1?.querySelector('.img-wrapper')?.querySelectorAll('[sectionid]') ||
                            element1?.querySelector('.recipient')?.querySelectorAll('[sectionid]') ||
                            element1?.querySelectorAll('[sectionid]');
                        clss = oldElement[0]?.getAttribute('class');
                    }
                } else {
                    oldElement = document.querySelectorAll(`#${att} .item.active [sectionid=${sectionId}]`);
                    if (oldElement?.length > 1 && dataIndex !== null) {
                        oldElement = document.querySelectorAll(
                            `#${att} .item.active [data-index='${dataIndex}'] [sectionid=${sectionId}]`
                        );
                    }
                    if (oldElement?.length === 0) {
                        oldElement = document.querySelectorAll(`[sectionid=${sectionId}]`);
                    }
                    clss = oldElement[0]?.getAttribute('class');
                }

                if (document.querySelectorAll('.broadCastCollapsible.collapse img')[0]) {
                    if (
                        document.querySelectorAll('.broadCastCollapsible.collapse img')[0]?.getAttribute('class') ===
                        'documentImg'
                    )
                        clss = document
                            .querySelectorAll('.broadCastCollapsible.collapse img')[0]
                            .parentElement.getAttribute('class');
                    else
                        clss = document
                            .querySelectorAll('.broadCastCollapsible.collapse img')[0]
                            ?.getAttribute('class');
                }
                if (document.querySelectorAll('.broadCastCollapsible.collapse iframe')[0]) {
                    clss = document.querySelectorAll('.broadCastCollapsible.collapse iframe')[0]?.getAttribute('class');
                }
                if (
                    document
                        ?.querySelectorAll('.broadCastCollapsible.collapse div')[0]
                        ?.children[0]?.getAttribute('onclick')
                ) {
                    clss = document
                        .querySelectorAll('.broadCastCollapsible.collapse div')[0]
                        .children[0]?.getAttribute('class');
                }
                clss = clss?.replace('document-background-color', '');
                clss = clss?.replace('background-color-darkbrown', '');
                clss = clss?.replace('align-center-wrapper', '');
                if (
                    oldElement[idx]?.parentElement?.classList?.contains('gifOnlyBackgroundBlack') &&
                    !img?.filename?.toLocaleLowerCase()?.endsWith('.gif')
                ) {
                    oldElement[idx].parentElement.classList.remove('gifOnlyBackgroundBlack');
                    clss = clss.replace('gifOnly', '');
                }
                if (oldElement?.length > 0) {
                    if (asset == 'image' || asset == 'gif') {
                        const imgElement = document.createElement('img');
                        imgElement.setAttribute('sectionid', sectionId + '_img');
                        imgElement.setAttribute('ineditmode', false);
                        imgElement.setAttribute('src', img?.base64Image);
                        imgElement.setAttribute('frameborder', 0);
                        imgElement.setAttribute('alt', img?.altText ? img?.altText : sectionId + '_img');
                        imgElement.setAttribute('class', clss?.trim());
                        if (!imgElement.getAttribute('data-originalpath'))
                            imgElement.setAttribute('data-originalpath', assetPreviewImage?.data);
                        if (!imgElement.getAttribute('data-filename'))
                            imgElement.setAttribute('data-filename', img?.filename);
                        if (imgElement.getAttribute('alt')) imgElement.setAttribute('alt', img?.altText);
                        imgElement.classList.add(sectionId?.trim() + '_img');
                        if (img?.filename?.toLocaleLowerCase()?.endsWith('.gif')) {
                            imgElement.classList.add('gifOnly');
                            if (oldElement[idx]?.parentNode)
                                oldElement[idx].parentNode.classList.add('gifOnlyBackgroundBlack');
                        }
                        oldElement[idx]?.replaceWith(imgElement);
                    } else if (asset == 'video') {
                        WistiaEditorIFrame(img, sectionId, clss, oldElement[idx]);
                    } else if (asset == 'file') {
                        const srcArr = img.split('/');
                        if (
                            (oldElement[idx]?.children[0]?.children[0]?.classList?.contains('inline-textEditor') &&
                                !oldElement[idx]?.classList?.contains('doc-download-btn')) ||
                            (oldElement[idx]?.classList?.contains('assetseditor') &&
                                oldElement[idx]?.hasAttribute('actionurl'))
                        ) {
                            // Document replace
                            oldElement[idx].setAttribute('href', img);
                            oldElement[idx].setAttribute('title', contentData?.text);
                            oldElement[idx].setAttribute('aria-label', oldElement[idx]?.innerText);
                        } else if (oldElement[idx].childNodes.length === 5) {
                            const anchorElement = document.createElement('a');
                            anchorElement.setAttribute('tabindex', oldElement[idx].getAttribute('tabindex'));
                            anchorElement.setAttribute('role', oldElement[idx].getAttribute('role'));
                            anchorElement.setAttribute('sectionid', oldElement[idx].getAttribute('sectionid'));
                            anchorElement.setAttribute('class', oldElement[idx].getAttribute('class'));
                            anchorElement.setAttribute('ineditmode', oldElement[idx].getAttribute('ineditmode'));
                            anchorElement.setAttribute('target', oldElement[idx].getAttribute('target'));
                            anchorElement.setAttribute('href', img);
                            anchorElement.setAttribute('title', contentData?.text);
                            anchorElement.setAttribute('aria-label', anchorElement?.innerText);

                            NodeList.prototype.forEach = Array.prototype.forEach;
                            var children = oldElement[idx].childNodes;
                            children.forEach(function (item) {
                                var cln = item.cloneNode(true);
                                anchorElement.appendChild(cln);
                            });

                            oldElement[idx]?.replaceWith(anchorElement);
                        } else if (
                            oldElement[idx].closest('.for-download:not(.Narrative)') ||
                            oldElement[idx]?.classList?.contains('doc-download-btn') ||
                            oldElement[idx]?.classList?.contains('download-doc-panel-btn') ||
                            oldElement[idx]?.classList?.contains('documentEditor') ||
                            (oldElement[idx]?.classList?.contains('assetseditor') &&
                                oldElement[idx]?.parentNode?.parentNode?.querySelector('.documentEditor'))
                        ) {
                            // Doc + Download
                            if (oldElement[idx]?.classList?.contains('inline-textEditor')) {
                                const replaceableElement =
                                    oldElement[idx]?.querySelector('.assetseditor:not(.inline-textEditor)') ||
                                    oldElement[idx]?.closest('.assetseditor:not(.inline-textEditor)');
                                if (replaceableElement) {
                                    replaceableElement.setAttribute('title', contentData?.text);
                                    replaceableElement.setAttribute('aria-label', replaceableElement?.innerText);
                                    replaceableElement.setAttribute('onclickprivate', img);
                                }
                                // oldElement[idx].parentNode.setAttribute('title', contentData?.text);
                                // oldElement[idx].parentNode.setAttribute('onclickprivate', img);
                            } else {
                                oldElement[idx].setAttribute('title', contentData?.text);
                                oldElement[idx].setAttribute('aria-label', oldElement[idx]?.innerText);
                                oldElement[idx].setAttribute('onclickprivate', img);
                            }
                        } else {
                            const divElement = document.createElement('div');
                            divElement.setAttribute('sectionid', sectionId);
                            divElement.setAttribute('ineditmode', false);
                            divElement.setAttribute('frameborder', 0);
                            divElement.setAttribute(
                                'class',
                                clss.trim() +
                                    ' document-background-color background-color-darkbrown align-center-wrapper'
                            );
                            divElement.setAttribute('title', contentData?.text);
                            divElement.setAttribute('aria-label', divElement?.innerText);
                            divElement.setAttribute('onclickprivate', img);
                            const imgElement = document.createElement('img');
                            imgElement.setAttribute('class', 'documentImg');
                            imgElement.setAttribute('alt', sectionId + '_img');
                            imgElement.setAttribute('src', window?.docIconAssetPath || checkIcon);
                            divElement.appendChild(imgElement);
                            oldElement[idx]?.replaceWith(divElement);
                        }
                    }
                }
                modalDispatch({
                    type: modalActions.HIDE_MODAL,
                });
            }
            setLoader(false);
            setTimeout(() => {
                isNotVimeo(asset, img) && Toast.success({ description: 'Asset replaced.' });
            }, 200);
            if (
                window.location.pathname.includes(RedirectTo('/site-edit')) ||
                window.location.pathname.includes(RedirectTo('/report-edit'))
            ) {
                setEditorChanges(true);
            }

            const photoGalleryCarousel = document?.querySelectorAll('#photoGalleryCarousel .frame_adjuster');
            if (photoGalleryCarousel?.length > 0) {
                setTimeout(() => {
                    initialSlideCenter();
                }, 1000);
            }
            window?.parralxAssetUploader && window.parralxAssetUploader();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        localStorage.removeItem('ReplaceAssetJstreeRendaring');
    }, []);

    return (
        <div className="jstree-custom-style">
            <p>Select file from cloud:</p>
            <Card className="p-1">{treeListData && <TreeView data={data} handleChange={handleGetList} />}</Card>
            <p>Upload file(s): {files?.length}</p>
            <Card className="p-2">
                <CustomLoader loader={loader} />
                <Row>
                    <Col className="uploader-block">
                        <FileUploader
                            className="asset-editor-replacer"
                            onFileUpload={(files) => setFiles(files)}
                            onRemoveFiles={(files) => setFiles(files)}
                            index={index}
                            files={files}
                            isSubmit={isSubmit}
                        />
                    </Col>
                    {!isSubmit && (
                        <Col md={4} className="text-block">
                            <SimpleBar>
                                <Timeline>
                                    <TimelineItem>
                                        {/* For success plz use mdi-check-bold bg-success-lighten text-success classes */}
                                        <i
                                            className={`mdi
                                        ${
                                            files?.length > 0
                                                ? 'mdi-check-bold bg-success-lighten text-success timeline-icon'
                                                : 'mdi-file bg-info-lighten text-info timeline-icon'
                                        }
                                        `}></i>
                                        {/* For success plz use text-primary class */}
                                        <div
                                            className={` block-1 ${
                                                files?.length > 0
                                                    ? 'text-primary timeline-item-info'
                                                    : 'timeline-item-info'
                                            }`}>
                                            <b>Select File</b>
                                        </div>
                                    </TimelineItem>

                                    <TimelineItem>
                                        <i
                                            className={`mdi 
                                    ${
                                        files?.length > 0 && !uploadDisabled
                                            ? 'mdi-check-bold bg-success-lighten text-success timeline-icon'
                                            : 'mdi-folder-open bg-info-lighten text-info timeline-icon'
                                    }
                                    `}></i>
                                        <div
                                            className={` block-2  ${
                                                files?.length > 0 && !uploadDisabled
                                                    ? 'text-primary timeline-item-info'
                                                    : 'timeline-item-info'
                                            }`}>
                                            <b>Select Location</b>
                                        </div>
                                    </TimelineItem>
                                </Timeline>
                            </SimpleBar>
                            <ButtonElement
                                data={files?.length > 0 && !uploadDisabled ? false : true}
                                name="Upload"
                                variant="primary"
                                type="button"
                                onClick={() => {
                                    setLoader(true);
                                    onUploadFile();
                                }}
                            />
                        </Col>
                    )}
                </Row>
            </Card>

            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Replace"
                    variant="outline-primary"
                    type="button"
                    disabled={addfolderDisabled}
                    onClick={() => onSubmit()}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.HIDE_MODAL,
                        })
                    }
                />
            </div>
        </div>
    );
};

export default ReplaceAssetEditorModal;
